<script setup lang="ts">
const slots = useSlots();
</script>

<template>
  <div class="page-title">
    <div class="page-title__inner">
      <div v-if="slots.start" class="page-title__slot--start">
        <slot name="start" />
      </div>
      <div class="page-title__slot--default">
        <h1 class="page-title__title"><slot /></h1>
      </div>
      <div v-if="slots.end" class="page-title__slot--end">
        <slot name="end" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-title {
  $this: &;
  @include media-breakpoint-up(lg) {
    @include box-shadow;
  }

  background-color: color(neutral, 200);

  padding: 16px;
  &__inner {
    @include container;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
    }
    min-height: 38px;

    display: grid;

    grid-template-columns: repeat(1, 1fr);
  }
  &__slot {
    display: flex;
    flex-direction: column;

    align-items: normal;
    justify-content: left;
    @include media-breakpoint-up(lg) {
      align-items: initial;
    }
    &--default,
    &--end {
      @extend #{$this}__slot;
    }
    &--default {
      @include media-breakpoint-up(lg) {
        grid-column: span 3;
      }
    }
    &--end {
      @include media-breakpoint-up(lg) {
        margin-top: 0;

        grid-column: span 2;
      }

      margin-top: 16px;
    }
  }
  &__title {
    color: color(neutral, 800);

    margin: 0;

    font-weight: 400;
  }
}
</style>
